import { SVGProps } from 'react';

const YellowDash = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='32' height='2' viewBox='0 0 32 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='0.5' width='32' height='1' fill='#FFDB01' />
    </svg>
  );
};

export default YellowDash;
