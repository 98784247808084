import tw, { css, styled, theme } from 'twin.macro';
import { ModalProps } from './Modal.types';

export const ModalContainer = styled.div<Pick<ModalProps, 'toDisplay'>>`
  ${tw`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
  z-index: ${p => (p.toDisplay ? '50' : `-1`)};
  transition: z-index 400ms;
`;

export const Overlay = styled.div<Pick<ModalProps, 'toDisplay'>>`
  ${tw`fixed inset-0 bg-dark-high`}
  will-change: opacity;
  z-index: ${p => (p.toDisplay ? '40' : `-1`)};
  ${p => (p.toDisplay ? tw`opacity-90` : tw`opacity-0`)}
  transition: opacity 300ms ease-in-out, z-index 400ms ease-in-out;
  transition-delay: ${p => (p.toDisplay ? '0s' : `0.15s`)};
`;

export const Popup = styled.div<Pick<ModalProps, 'toDisplay' | 'width'>>`
  width: ${p => (p.width ? `${p.width}px` : `350px`)};
  ${tw`fixed p-3 rounded-2xl z-10 me-3 sm:me-0`}
  background: #202025;
  box-shadow: 0px 48px 96px -8px rgba(75, 75, 77, 1);
  border-radius: 8px;
  will-change: transform;
  opacity: ${p => (p.toDisplay ? 1 : 0)};
  transform: ${p => (p.toDisplay ? 'translateY(0)' : `translateY(100vh)`)};
  transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
  transition-delay: ${p => (p.toDisplay ? '0.2s' : `0`)};
  ${css`
    @media (max-width: ${theme`screens.md.max`}) {
      max-width: 92vw;
    }
  `}
`;
