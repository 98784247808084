import ClickAwayListener from 'react-click-away-listener';
import { ModalContainer, Overlay, Popup } from './Modal.styles';
import { ModalProps } from './Modal.types';

const Modal = ({ width, toDisplay, children, onClickAway }: ModalProps): JSX.Element => {
  return (
    <>
      <ModalContainer toDisplay={toDisplay}>
        <ClickAwayListener
          onClickAway={() => {
            onClickAway && toDisplay && onClickAway();
          }}
        >
          <Popup width={width} toDisplay={toDisplay}>
            {children}
          </Popup>
        </ClickAwayListener>
      </ModalContainer>
      <Overlay toDisplay={toDisplay} />
    </>
  );
};

export default Modal;
