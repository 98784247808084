import { Body1, Heading6 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const HowSection = styled.section`
  ${tw`flex flex-col col-span-full relative`}
  ${tw`sm:hidden`}
  ${tw`md:items-center md:pt-6 md:pb-8`}
  ${tw`lg:justify-center lg:pt-12 lg:pb-15`}
  ${tw`xl:justify-center xl:pt-12 xl:pb-15`}
`;

export const HowTitle = styled.p`
  ${tw`text-light-high text-center font-bold`}
  ${tw`sm:text-headline-4`}
  ${tw`md:text-headline-4`}
  ${tw`lg:text-headline-2`}
  ${tw`xl:text-headline-2`}
`;

export const HowFlexbox = styled.div`
  ${tw`flex items-center`}
  ${tw`sm:flex-col sm:space-y-5 sm:pt-6`}
  ${tw`md:flex-col md:space-y-5 md:pt-6`}
  ${tw`lg:justify-evenly lg:pt-8`}
  ${tw`xl:justify-evenly xl:pt-8`}
`;

export const HowStep = styled.div`
  ${tw`flex flex-col items-center`}
`;

export const HowStepTitle = styled(Heading6)`
  ${tw`text-light-medium pb-2`}
`;

export const HowStepText = styled(Body1)`
  ${tw`text-light-low`}
`;

export const HowSectionSmall = styled.section`
  ${tw`col-span-full pt-3 pb-3 relative`}
  ${tw`md:hidden`}
  ${tw`lg:hidden`}
  ${tw`xl:hidden`}
`;

export const HowTitleSmall = styled.p`
  ${tw`text-light-high font-bold text-headline-4 mb-4`}
`;

export const HowStepperSmall = styled.div`
  ${tw`flex flex-col text-light-low`}
  ${tw`ms-3`}
`;

export const HowStepSmall = styled.div<{ noBorder?: boolean }>`
  ${tw`relative pb-3 ps-4 border-s-2`}
  border-color: rgb(32, 32, 37);
  ${props => props.noBorder && tw`border-transparent`};
`;

export const HowStepNumberSmall = styled.div`
  background-color: rgb(32, 32, 37);
  border-radius: 64px;
  padding-left: 12px;
  padding-right: 12px;
  ${tw`absolute pt-1 pb-1 text-light-high text-subtitle-2 font-bold`}
  [dir='rtl'] & {
    right: -17px;
  }
  [dir='ltr'] & {
    left: -17px;
  }
`;

export const HowStepTitleSmall = styled(Heading6)`
  ${tw`text-light-high pb-1`}
`;

export const HowStepTextSmall = styled(Body1)``;

export const GrayBackground = styled.div`
  z-index: -1;
  ${tw`absolute inset-y-0`}
  ${tw`sm:-left-2 sm:-right-2`}
  ${tw`md:-left-3 md:-right-3`}
  ${tw`lg:-left-8 lg:-right-8`}
  ${tw`xl:-left-8 xl:-right-8`}
  background-color: rgb(24, 24, 27);
`;
