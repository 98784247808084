import { SVGProps } from 'react';

export default function SentEmail(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M9.19713 52.0935C7.99278 52.0935 6.82115 51.7014 5.85929 50.9767C4.89743 50.2519 4.1976 49.2338 3.86555 48.0761C3.53349 46.9184 3.58726 45.6841 4.01872 44.5597C4.45018 43.4353 5.23589 42.4819 6.25713 41.8435L67.8671 3.33014C68.7811 2.75817 69.842 2.46516 70.92 2.48698C71.9979 2.50879 73.0461 2.8445 73.9362 3.45297C74.8263 4.06145 75.5196 4.91628 75.9312 5.91279C76.3429 6.9093 76.455 8.00422 76.2538 9.06347L64.2538 72.9735C64.0547 74.0308 63.5523 75.0074 62.8079 75.7842C62.0636 76.5611 61.1094 77.1047 60.0615 77.3488C59.0137 77.5929 57.9174 77.527 56.9064 77.1591C55.8953 76.7913 55.0131 76.1372 54.3671 75.2768L37.0005 52.0935H9.19713Z'
          stroke='#49E3AC'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M37.0002 52.0952H28.6668V69.3319C28.6668 70.3402 28.9417 71.3295 29.4621 72.1931C29.9825 73.0568 30.7287 73.7621 31.6202 74.2333C32.5117 74.7044 33.5148 74.9234 34.5215 74.8668C35.5282 74.8102 36.5005 74.48 37.3335 73.9119L47.9268 66.6919L37.0002 52.0952Z'
          stroke='#49E3AC'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M36.9969 52.0957L73.9303 3.4624' stroke='#49E3AC' strokeWidth='5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='80' height='80' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
