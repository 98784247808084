import { SVGProps } from 'react';

const CheckCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5003 2.66699C13.8632 2.66699 11.2854 3.44898 9.09273 4.91406C6.90007 6.37915 5.19111 8.46153 4.18194 10.8979C3.17277 13.3342 2.90872 16.0151 3.42319 18.6015C3.93766 21.1879 5.20754 23.5637 7.07224 25.4284C8.93694 27.2931 11.3127 28.563 13.8991 29.0775C16.4855 29.5919 19.1664 29.3279 21.6028 28.3187C24.0391 27.3095 26.1215 25.6006 27.5866 23.4079C29.0517 21.2153 29.8337 18.6374 29.8337 16.0003C29.8295 12.4654 28.4235 9.07638 25.9239 6.57678C23.4243 4.07719 20.0353 2.67111 16.5003 2.66699V2.66699ZM24.197 11.7781L16.5914 22.0992C16.5016 22.2183 16.389 22.3184 16.2601 22.3935C16.1313 22.4687 15.9887 22.5174 15.8408 22.5368C15.6929 22.5563 15.5426 22.5461 15.3987 22.5068C15.2547 22.4675 15.1201 22.4 15.0026 22.3081L9.57144 17.9659C9.45748 17.8747 9.3626 17.7619 9.29222 17.6341C9.22183 17.5062 9.17732 17.3657 9.16123 17.2207C9.12872 16.9277 9.21393 16.6338 9.39811 16.4037C9.58229 16.1735 9.85035 16.026 10.1433 15.9934C10.2884 15.9773 10.4352 15.99 10.5754 16.0306C10.7156 16.0713 10.8464 16.1391 10.9603 16.2303L15.4892 19.8537L22.4081 10.4637C22.4914 10.3387 22.5992 10.2319 22.7249 10.1497C22.8506 10.0675 22.9917 10.0117 23.1397 9.98561C23.2876 9.95951 23.4392 9.96368 23.5855 9.99785C23.7318 10.032 23.8696 10.0955 23.9906 10.1844C24.1117 10.2734 24.2134 10.3859 24.2897 10.5153C24.3661 10.6447 24.4153 10.7882 24.4346 10.9371C24.4539 11.0861 24.4428 11.2374 24.402 11.382C24.3611 11.5265 24.2914 11.6613 24.197 11.7781Z'
        fill='#49E3AC'
      />
    </svg>
  );
};

export default CheckCircle;
