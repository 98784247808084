export const I18N_COMMON_CONTEXT = 'common';

export const HOW_TITLE = 'how-section-title';
export const HOW_TITLE_SMALL = 'how-section-title-small';

export const HOW_STEP_1_TITLE = 'how-step-1-title';
export const HOW_STEP_1_TEXT = 'how-step-1-text';

export const HOW_STEP_2_TITLE = 'how-step-2-title';
export const HOW_STEP_2_TEXT = 'how-step-2-text';

export const HOW_STEP_3_TITLE = 'how-step-3-title';
export const HOW_STEP_3_TEXT = 'how-step-3-text';
