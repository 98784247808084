import YellowDash from '@assets/yellow_dash';
import useTranslation from 'next-translate/useTranslation';
import {
  HOW_STEP_1_TEXT,
  HOW_STEP_1_TITLE,
  HOW_STEP_2_TEXT,
  HOW_STEP_2_TITLE,
  HOW_STEP_3_TEXT,
  HOW_STEP_3_TITLE,
  HOW_TITLE,
  HOW_TITLE_SMALL,
  I18N_COMMON_CONTEXT,
} from './HowWorksSection.constants';
import {
  GrayBackground,
  HowFlexbox,
  HowSection,
  HowSectionSmall,
  HowStep,
  HowStepNumberSmall,
  HowStepperSmall,
  HowStepSmall,
  HowStepText,
  HowStepTextSmall,
  HowStepTitle,
  HowStepTitleSmall,
  HowTitle,
  HowTitleSmall,
} from './HowWorksSection.styles';

const HowWorksSection = ({ isProfile }: { isProfile?: boolean }): JSX.Element => {
  const { t } = useTranslation(I18N_COMMON_CONTEXT);

  return (
    <>
      <HowSection id='how'>
        {isProfile && <GrayBackground />}
        <HowTitle>{t(HOW_TITLE)}</HowTitle>
        <HowFlexbox>
          <HowStep>
            <HowStepTitle>{t(HOW_STEP_1_TITLE)}</HowStepTitle>
            <HowStepText>{t(HOW_STEP_1_TEXT)}</HowStepText>
          </HowStep>
          <YellowDash />
          <HowStep>
            <HowStepTitle>{t(HOW_STEP_2_TITLE)}</HowStepTitle>
            <HowStepText>{t(HOW_STEP_2_TEXT)}</HowStepText>
          </HowStep>
          <YellowDash />
          <HowStep>
            <HowStepTitle>{t(HOW_STEP_3_TITLE)}</HowStepTitle>
            <HowStepText>{t(HOW_STEP_3_TEXT)}</HowStepText>
          </HowStep>
        </HowFlexbox>
      </HowSection>
      <HowSectionSmall id='how'>
        {isProfile && <GrayBackground />}
        <HowTitleSmall>{t(HOW_TITLE_SMALL)}</HowTitleSmall>
        <HowStepperSmall>
          <HowStepSmall>
            <HowStepNumberSmall>1</HowStepNumberSmall>
            <HowStepTitleSmall>{t(HOW_STEP_1_TITLE)}</HowStepTitleSmall>
            <HowStepTextSmall>{t(HOW_STEP_1_TEXT)}</HowStepTextSmall>
          </HowStepSmall>
          <HowStepSmall>
            <HowStepNumberSmall>2</HowStepNumberSmall>
            <HowStepTitleSmall>{t(HOW_STEP_2_TITLE)}</HowStepTitleSmall>
            <HowStepTextSmall>{t(HOW_STEP_2_TEXT)}</HowStepTextSmall>
          </HowStepSmall>
          <HowStepSmall noBorder>
            <HowStepNumberSmall>3</HowStepNumberSmall>
            <HowStepTitleSmall>{t(HOW_STEP_3_TITLE)}</HowStepTitleSmall>
            <HowStepTextSmall>{t(HOW_STEP_3_TEXT)}</HowStepTextSmall>
          </HowStepSmall>
        </HowStepperSmall>
      </HowSectionSmall>
    </>
  );
};
export default HowWorksSection;
